import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import PageHeader from "../primitive/PageHeader";
import Navbar from "../connected/Navbar";
import Footer from "../connected/Footer";
import { useParams } from "react-router-dom";
import axios from "axios";
import { ProgressSpinner } from "primereact/progressspinner";
import date_icon from "../../assets/icons/date_icon.svg";
import moment from "moment";
import RelatedPosts from "../sections/BlogSection/RelatedPosts";

const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
const BLOG_ID = process.env.REACT_APP_GOOGLE_BLOG_ID;

const BlogDetails = () => {
  const { id } = useParams();
  const elementRef = useRef();
  const [loading, setLoading] = useState(false);
  const [singlePost, setSinglePost] = useState(null);
  const [allPosts, setAllPosts] = useState([]);

  const getAllPosts = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `https://www.googleapis.com/blogger/v3/blogs/${BLOG_ID}/posts?key=${API_KEY}`
      );
      const filtered_posts = response.data.items?.filter(
        (post) => post?.id !== id
      );
      setAllPosts(filtered_posts);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error({ error });
    }
  };

  const getSinglePosts = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `https://www.googleapis.com/blogger/v3/blogs/${BLOG_ID}/posts/${id}?key=${API_KEY}`
      );
      setSinglePost(response?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error({ error });
    }
  };

  useEffect(() => {
    getSinglePosts();
    getAllPosts();
  }, [id]);

  // useEffect(() => {
  //   if (elementRef.current) {
  //     let value = document.querySelector(".details");
  //     console.log({ value });
  //     value.style.width = "200px";
  //     console.log({ value });
  //     // set other style attributes
  //   }
  // }, [elementRef, singlePost, allPosts]);

  // console.log({ singlePost, elementRef });
  return (
    <div>
      <PageHeader browserTitle="Blog Details | Smartcomply" />
      <div className="get_in_touch_hero_section_bg">
        <Navbar />
        <div>
          <div className="container mx-auto py-5 md:py-10 mb-6 space-y-12">
            <div className="w-full space-y-4">
              <div className="flex items-center space-x-2">
                <>
                  {singlePost?.labels?.map((label, index) => (
                    <span
                      key={index}
                      className="w-fit text-[10px] rounded-[4px] bg-FloralWhite border border-Chardonnay py-0.5 px-1 font-test-sohne-semibold text-CharcoalGrey"
                    >
                      {label}
                    </span>
                  ))}
                </>
                <img src={date_icon} alt="" />
                <span className="text-xs font-test-sohne-light">
                  {moment(singlePost?.date_posted).format("MMMM do, YYYY")}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container lg:mt-[-3rem]">
        <div>
          {loading ? (
            <ProgressSpinner
              style={{ width: "40px", height: "40px" }}
              className="flex justify-center items-center"
              strokeWidth="8"
              fill="white"
              animationDuration=".5s"
            />
          ) : (
            <div>
              <h1 className="text-[30px] font-[bold]">{singlePost?.title}</h1>
              <div
                className="details"
                dangerouslySetInnerHTML={{ __html: singlePost?.content }}
                ref={elementRef}
              />{" "}
            </div>
          )}
        </div>
        <div className="mt-[3rem]">
          <h1 className="font-bold text-[34px]">Related Posts</h1>
          <div className="mt-[2rem] flex justify-between items-center flex-wrap">
            {allPosts?.slice(0, 6)?.map((post) => (
              <RelatedPosts post={post} key={post?.id} />
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default BlogDetails;
